(function () {
    'use strict';

    angular
        .module('aerosApp')
        .component('aflActionButton', {
            controller: aflActionButtonController,
            templateUrl: '/static/javascript/directives/afl-action-button/afl-action-button.html',
            bindings: {
                onClick: '&',
                disabled: '<',
                icon: '@',
                label: '@',
                placement: '='
            }
        });

    aflActionButtonController.$inject = [];

    function aflActionButtonController() {

    }
})();
